<template>
  <CRow>
    <CCol col="12">
      <CModal :show.sync="op_confirm.show" :centered="true" :title="$t(op_confirm.title) + ` [ID: ${op_confirm.value.id}]`" size="lg">
        {{ $t(op_confirm.message) }}
        <template #footer>
          <CButton type="button" class="mr-2" :color="op_confirm.color_yes || 'primary'" @click="onModalConfirmed(op_confirm.response_yes || true)">{{ $t(op_confirm.yes || 'Yes') }}</CButton>
          <CButton v-if="op_confirm.no" type="button" :color="op_confirm.color_no || 'default'" @click="onModalConfirmed(op_confirm.response_no || false)">{{ $t(op_confirm.no || 'No') }}</CButton>
          <CButton v-if="op_confirm.cancel !== false" type="button" :color="op_confirm.color_cancel || 'secondary'" @click="onModalConfirmed(op_confirm.response_cancel)">{{ $t('Cancel') }}</CButton>
        </template>
      </CModal>

      <CCard no-header>
        <CCardBody>
          <h3>{{ $t('Imputation order') }}</h3>
          <ACard>
            <div>
              <CButton v-if="dirty" color="primary" @click="submit">{{ $t('Save') }}</CButton>
              <span class="mb-3 float-right">
                <span v-if="selected.key">
                  <span class="text-muted">{{ $t('Selected') }}:</span>
                  <b class="ml-2">{{ selected.index + 1 }}</b>
                  <span class="ml-2">[{{ $t(_.capitalize(selected.type)) }}]</span>
                </span>
                <span v-else>
                  <span class="text-muted">{{ $t('Selected') }}:</span>
                  <span class="ml-2">{{ $t('No option selected') }}</span>
                </span>

                <span class="ml-3">
                  <CButton :disabled="!selected.key || selected.index < 1" color="info" @click="setPosition('up')">
                    <CIcon name="cil-chevron-top" />
                  </CButton>
                  <CButton :disabled="!selected.key || selected.index >= lasts[selected.type]" color="info" @click="setPosition('down')">
                    <CIcon name="cil-chevron-bottom" />
                  </CButton>
                  <CButton :disabled="!selected.key || selected.index < 1" color="info" @click="setPosition('top')">
                    <CIcon name="cil-chevron-double-up" />
                  </CButton>
                  <CButton :disabled="!selected.key || selected.index >= lasts[selected.type]" color="info" @click="setPosition('bottom')">
                    <CIcon name="cil-chevron-double-down" />
                  </CButton>
                  <CButton :disabled="!selected.key" color="danger" @click="removeItem">
                    <CIcon name="cil-x" />
                  </CButton>
                </span>
              </span>
            </div>

            <div class="position-relative table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>{{ $t('Order') }}</th>
                    <th v-for="type of types" :key="type" style="width: 17%">{{ $t(_.capitalize(type)) }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(id, index) of rows.ids" :key="index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td v-for="type of types" :key="type" class="order-item" :class="{ 'bg-info': `${type}_${index}` === selected.key }" @click="setSelected(type, index)">
                      <span v-if="index < rows[type].length">{{ rows[type][index] }}</span>
                      <span v-else> - </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ACard>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'

export default {
  name: 'ImputationOrder',
  mixins: [formMixin],
  data: () => {
    return {
      types: ['application', 'payments', 'reports', 'invoices', 'receipts'],
      rows: { ids: [] },
      lasts: {},
      dirty: false,
      classes: {},
      selected: {},

      op_confirm: { show: false, title: 'Remove row', message: 'Are you sure you want to delete this row?', value: {} }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    submit() {
      const self = this
      this.$http
        .post('admin/property_imputation_order', { rows: self.rows })
        .then(response => {
          self.showAlert('Imputation order saved')
          this.prepareData(response.data)
        })
        .catch(error => {
          self.showAlert('There was an error saving the imputation order.', 'danger') // TODO: IMPORTANT!!! Make alerts and translation service dynamic (use attributes and pluralization)
          console.error(error)
        })
    },
    getData() {
      const self = this
      self.$http
        .get('admin/property_imputation_order')
        .then(response => {
          this.prepareData(response.data)
        })
        .catch(error => {
          console.error(error)
        })
    },

    prepareData(data) {
      this.rows.ids = []
      for (const type of this.types) {
        this.rows[type] = []
        this.lasts[type] = 0
      }

      let last = 0
      const used_classes = []
      for (const order of data.imputation_order) {
        last = order.id
        this.rows.ids.push(order.id)
        for (const type of this.types) {
          if (order[type]) {
            used_classes.push(order[type])
            this.rows[type].push(order[type])
          }
        }
      }

      for (const _class of data.imputation_classes.filter(item => used_classes.indexOf(item.id) === -1)) {
        last++
        this.rows.ids.push(last)
        for (const type of this.types) this.rows[type].push(_class.id + ' *')
      }

      for (const type of this.types) this.lasts[type] = this.rows[type].length - 1
    },
    setSelected(type, index) {
      if (this.selected.type === type && this.selected.index === index) this.selected = {}
      else {
        this.selected = {}
        const option = this.rows[type][index]
        if (option) this.selected = { type, index, option, key: `${type}_${index}` }
      }
    },
    setPosition(type) {
      this.dirty = true
      const last = this.lasts[this.selected.type]
      const array = this.rows[this.selected.type]
      const index = parseInt(this.selected.index)

      let new_index = -1
      switch (type) {
        case 'up':
          if (this.selected.index > 0) new_index = index - 1
          break
        case 'down':
          if (this.selected.index < last) new_index = index + 1
          break
        case 'top':
          if (this.selected.index > 0) new_index = 0
          break
        case 'bottom':
          if (this.selected.index < last) new_index = last
          break
      }

      if (new_index !== -1) {
        array.splice(new_index, 0, array.splice(this.selected.index, 1)[0])
        this.rows[this.selected.type] = array
        this.setSelected(this.selected.type, new_index)
      }
    },
    removeItem() {
      const type = this.selected.type
      this.op_confirm.value = { id: _.capitalize(type) + ' - ' + this.rows[type][this.selected.index] }
      this.op_confirm.show = true
    },
    onModalConfirmed(response) {
      this.op_confirm.show = false
      if (response) {
        this.rows[this.selected.type].splice(this.selected.index, 1)
        this.selected = {}
        this.dirty = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
th,
td {
  text-align: center;
}
.order-item {
  cursor: pointer;
}
</style>
